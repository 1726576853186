import Head from "next/head";

const Meta = ({ title, keyword, desc }) => {
  return (
    <div>
      <Head>
        <title>{`${title} || Seize financial independence before it becomes someone else's prize`}</title>
        <link rel="icon" href="/favicon.png" />
        <meta name="description" content={desc} />
        <meta name="keyword" content={keyword} />
      </Head>
    </div>
  );
};

Meta.defaultProps = {
  title: "Sollotto || Seize financial independence before it becomes someone else's prize",
  keyword:
    "bitcoin, blockchain, crypto, crypto collectibles, crypto makretplace, cryptocurrency, digital items, market, nft, nft marketplace, nft next js, NFT react, non-fungible tokens, virtual asset, wallet",
  desc: "Sollotto is a unique skill trading reward system on the Solana blockchain, designed to incentivize active traders. Every day, Sollotto selects one trader who has traded Sollotto tokens within the past 24 hours to share 50% of the rewards pool.",
};

export default Meta;
