import { useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const RoadMap = () => {
  const [itemsTabs, setItemsTabs] = useState(1);

  const roadMapList = [
    {
      id: 1,
      tabMenu: "Q3 2024",
    },
    {
      id: 2,
      tabMenu: "Q4 2024",
    },
    {
      id: 3,
      tabMenu: "Q1 2025",
    },
    {
      id: 4,
      tabMenu: "Q2 2025",
    },
    {
      id: 5,
      tabMenu: "Q3 2025",
    },
  ];
  const roadMapContent = [
    {
      id: 1,
      text: [
        `• SolLotto launches on Meteora, Jupiter and Raydium.`,
        `• Create multiple pairs to give users their preferred style of trading SolLotto.`,
        `• Launch Daily Livestreams of the winning wallet & reward select members daily who join the stream.`,
        `• Introducing users to our innovative skill trading and reward system.`,
      ],
    },
    {
      id: 2,
      text: [
        "• Start listing on major decentralized exchanges (DEXs) to enhance accessibility and liquidity.",
        "• Expand trading opportunities & increase visibility within the broader crypto community.",
        "• Host live streams with different crypto influencers who have a good standing within the community.",
        "• Stream 24 hour continuous gaming session with Sammy SOL and Larry Lotto in Las Vegas.",
      ],
    },
    {
      id: 3,
      text: [
        `• Continue listing on major DEXs by letting the community vote on which ones are preferred.`,
        `• Enhancing the Sollotto experience by offering additional ways for users to benefit through our ecosystem.`,
        `• Begin the build out of Sollotto Casino Website.`,
        `• Drawings will be held on livestream for winning wallets holders to participate in person with Sammy Sol and the Sollotto team.`,
        `• Host/Stream Best Crypto Trader Competition at Sollotto HQ. 4 traders will go head to head for 24 hours/highest profit wins a reward chosen by Sollotto community.`,
      ],
    },
    {
      id: 4,
      text: [
        `• Finish launching on any Major DEXs that are remaining.`,
        `• Host first live event for ALL Sollotto hodlers - location TBD.`,
        `• Finalize and start testing Sollotto Casino Website.`,
      ],
    },
    {
      id: 5,
      text: [
        `• Establish a new scratch off ticket that Sollotto Team will promote physically where lottery tickets are sold in USA`,
        `• community decides on ticket style.`,
        `• Launch First 3 custom games on Sollotto Casino website.`,
      ],
    },
  ];

  return (
    <section
      className="relative bg-cover bg-center bg-no-repeat py-24 after:absolute after:inset-0 after:bg-jacarta-900/60"
      style={{
        backgroundImage: 'url("/images/ico-landing/ico_landing_roadmap.jpg")',
      }}
    >
      <div className="container relative z-10">
        <h2 className="mb-6 font-display text-3xl text-white">Roadmap</h2>
        <p className="mb-12 max-w-xl text-lg text-jacarta-300">
          This timeline details our funding and development goals.
        </p>

        <Tabs>
          <div className="flex">
            <TabList className="nav nav-tabs w-1/3 space-y-9 self-start border-l-2 border-jacarta-200 py-2 pl-2 md:pl-8">
              {roadMapList.map((item) => (
                <Tab
                  className="nav-item"
                  key={item.id}
                  onClick={() => setItemsTabs(item.id)}
                >
                  <button
                    className={
                      itemsTabs === item.id
                        ? "active nav-link nav-link--style-3 relative flex items-center whitespace-nowrap text-jacarta-300 hover:text-white"
                        : "nav-link nav-link--style-3 relative flex items-center whitespace-nowrap text-jacarta-300 hover:text-white"
                    }
                  >
                    <span className="px-2 font-display text-lg font-medium md:text-2xl">
                      {item.tabMenu}
                    </span>
                  </button>
                </Tab>
              ))}
            </TabList>
            {/* End Tablist  */}

            <div className="tab-content w-full pl-4 md:mt-16 md:w-2/4">
              {roadMapContent.map((item) => (
                <TabPanel key={item.id}>
                  {item.text.map((textItem) => (
                    <p className="text-lg text-white pb-2" key={textItem}>
                      {textItem}
                    </p>
                  ))}
                </TabPanel>
              ))}
            </div>
            {/* End tab-content */}
          </div>
        </Tabs>
      </div>
    </section>
  );
};

export default RoadMap;
