export const services_data = [
  {
    title: "Fair and Transparent Ecosystem",
    desc: "Every transaction is monitored, ensuring that all participants have an equal opportunity to benefit.",
    id: "0Fair and Transparent Ecosystem",
    icon: "service-ico-4",
  },
  {
    title: "Contest of Skill",
    desc: `The more Sollotto a Wallet Trades, the greater the chance of taking home that days Reward.`,
    id: "1Skill Contests",
    icon: "service-ico-5",
  },
  {
    title: "Daily Opportunities",
    desc: "Multiple ways to Earn Sollotto. Sollotto Hodlers who join the Live Stream will have a chance to Win Daily Rewards from the Live Streams.",
    id: "2Daily Opportunities",
    icon: "service-ico-6",
  },
];
