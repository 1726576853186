import React from "react";
import Process from "../../components/blog/process";
import Hero_4 from "../../components/hero/hero_4";
import Intro_video from "../../components/intro_video";
import Services from "../../components/services/services";
import Faq from "../../components/ico-landing/faq";
import RoadMap from "../../components/ico-landing/RoadMap";
import CallToActions from "../../components/ico-landing/CallToActions";
import Meta from "../../components/Meta";

const Home_1 = () => {
  return (
    <main>
      <Meta title="Sollotto" />
      {/* <Hero />
       */}
      <Hero_4 />
      <Intro_video />
      <Services />
      <Faq />
      <RoadMap />
      <Process />
      <CallToActions />
    </main>
  );
};

export default Home_1;
